import React from 'react';
import { InputText } from '@dls/web';
import { formatUsername } from '../../helpers/utils';

interface Props {
  name: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  bgColor?: 'white' | 'haze';
  hintMessage?: string;
  error?: boolean;
  format?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const InputIdentifier = React.forwardRef((props: Props, ref) => {
  return (
    <InputText
      {...props}
      value={props.value}
      ref={ref}
      onBlur={(e) => {
        const formattedValue = props.format ? formatUsername(e.target.value?.trim()) : e.target.value?.trim();
        props.onChange && props.onChange(formattedValue);
      }}
    />
  );
});

InputIdentifier.displayName = 'InputIdentifier';

export default InputIdentifier;
